<template>
    <div>
        <profile-detail :profile="profile" :properties="properties" :isOwner="true"></profile-detail>
    </div>
</template>

<script>
    export default {
        name: "UserProfile",
        data() {
            return {


            }
        },
        computed:{
            profile() {
                return this.$store.state.user_module.user;
            },
            properties(){
                let p = this.$store.state.user_module.user_properties;
                return p;
            }


        },
        methods: {
            getUserProfile() {
                this.$store.dispatch("getProfileAction");
            },

        },
        created() {
            this.getUserProfile()
        }
    }
</script>

<style scoped>

</style>