<template>
    <div>

        <v-form ref="profile" enctype="multipart/form-data">
            <v-stepper v-model="step" style="margin-top:-6px">
                <search-area t :elevate=0 height="0">
                    <template v-slot:item>
                        <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1" :editable="step ===1"
                            >
                                Account info
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="step > 2" :editable="step ===2"
                                            step="2"
                            >Location</v-stepper-step>
                            <v-stepper-step :complete="step > 2" :editable="step ===3"
                                            step="3"
                            >Image</v-stepper-step>
                        </v-stepper-header>
                    </template>
                </search-area>

                <!--            <v-divider></v-divider>-->
                <v-stepper-items >

                    <v-stepper-content step="1" >
                        <v-text-field ref="firstname" outlined label="First Name" type="text" :rules="[rules.required]" v-model="user.firstname"></v-text-field>
                        <v-text-field ref="lastname" outlined label="Last Name" type="text" :rules="[rules.required]" v-model="user.lastname"></v-text-field>
                        <v-text-field ref="email" outlined label="Email" type="text" :rules="[rules.email,rules.required]" v-model="user.email"></v-text-field>
                        <v-text-field ref="phone" outlined label="Phone Number" type="text" :rules="[rules.required]" v-model="user.phone"></v-text-field>

                        <!--                        <div class="step_button">-->
                        <!--                            <v-btn text @click="step&#45;&#45;" v-if="step !== 1">Back</v-btn>-->
                        <!--                            <v-spacer></v-spacer>-->
                        <!--                            <v-btn  color="secondary" width="120" @click="step++">-->
                        <!--                                <span style="color: #fff;text-transform: capitalize;font-size: 15px">Continue <v-icon size="20">mdi-chevron-right</v-icon></span>-->
                        <!--                            </v-btn>-->
                        <!--                        </div>-->
                        <!--                        -->

                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-autocomplete
                                v-model="user.state_id"
                                :items="states"
                                :search-input.sync="search"
                                item-value="id"
                                item-text="name"
                                cache-items
                                :rules="[rules.required]"
                                placeholder="eg Lagos"

                                label="State"
                                clearable
                                outlined
                        ></v-autocomplete>
                        <!--                        <v-select v-model="form.state_id"  clearable placeholder="Lagos"-->
                        <!--                                :items="states"-->
                        <!--                                item-text="name"-->
                        <!--                                item-value="id"-->
                        <!--                                label="State"-->
                        <!--                                outlined-->
                        <!--                        ></v-select>-->
                        <v-text-field ref="city" outlined label="City" placeholder="Ikeja" type="text" :rules="[rules.required]" v-model="user.city"></v-text-field>
                        <v-textarea :rules="[rules.required]" v-model="user.address" auto-grow height="60" placeholder="20, okoro street"
                                    outlined
                                    label="Address" clearable
                        ></v-textarea>
<!--                        <v-textarea :rules="[rules.required]" v-model="user.profile_content" auto-grow height="100"-->
<!--                                    outlined-->
<!--                                    label="Profile Intro" clearable-->
<!--                        ></v-textarea>-->
                        <my-vue-editor :rules="[rules.required]" v-model="user.profile_content"></my-vue-editor>


                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container>
                            <div @click="launchFilePicker()" class="text-center">
                              <v-avatar size="150px" v-ripple v-if="!avatar" class="grey lighten-3 mb-3">-->
                                    <span>Click to upload</span>
                                </v-avatar>
                                <v-avatar size="150px" v-ripple v-else class="mb-3">
                                    <img :src="avatar.imageURL" alt="avatar">
                                </v-avatar>
                            </div>
                            <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
                            <input type="file"
                                   ref="file"
                                   :name="uploadFieldName"
                                   @change="onFileChange(
          $event.target.name, $event.target.files)"
                                   style="display:none">

                            <v-dialog v-model="errorDialog" max-width="300">
                                <v-card>
                                    <v-card-text class="subheading">{{errorText}}</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="errorDialog = false" flat>Got it!</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

<!--                        <image-input v-model="avatar">-->
<!--                            <div slot="activator">-->
<!--                                <v-avatar size="150px" v-ripple v-if="!avatar" class="grey lighten-3 mb-3">-->
<!--                                    <span>Click to upload</span>-->
<!--                                </v-avatar>-->
<!--                                <v-avatar size="150px" v-ripple v-else class="mb-3">-->
<!--                                    <img :src="avatar.imageURL" alt="avatar">-->
<!--                                </v-avatar>-->
<!--                            </div>-->
<!--                        </image-input>-->
<!--                        <v-slide-x-transition group>-->
                            <div class="text-center" v-if="user.photo !== ''">click on the image to change picture</div>
                            <div v-if="avatar && saved === false" class="text-center">

<!--                                <v-btn color="secondary" @click="uploadImage" :loading="saving"> <span style="color: #fff">Save Avatar</span></v-btn>-->
                            </div>
<!--                        </v-slide-x-transition>-->
                        </v-container>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>



            <v-bottom-navigation app :fixed=true  style="justify-content: space-evenly;padding-left:10px">
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  @click="step--" v-if="step !== 1" class="offer_btn" large dark style=" border-radius: 5px;color: #379392 !important;background-color: #eff6f5 !important;">Back</v-btn>
                </div>
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  :loading="apiloading"
                           :disabled="apiloading"
                           color="secondary" @click.prevent="step===3?updateProfile():step++" class="inspection_btn" style=" border-radius: 5px;color: #fff !important;"> {{step===3?'Submit':'Continue'}}</v-btn>
                </div>
            </v-bottom-navigation>
        </v-form>


    </div>
</template>

<script>
    import ImageInput from "../../../components/form/ImageInput";
    import {loadingMixin} from "../../../mixins/Loading";
    import toast from "../../../Services/ToastService";

    export default {
        name: "EditProfile",
        mixins:[loadingMixin],
        components: {
            ImageInput: ImageInput
        },
        data() {
            return {
                errorDialog: null,
                errorText: '',
                uploadFieldName: 'file',
                maxSize: 1024,
                step:1,
                search:'',
                avatar: {
                    formData:'',
                    imageURL:''
                },
                saving: false,
                saved: false,
                user: {
                    firstname:'',
                    lastname:'',
                    email:'',
                    phone:'',
                    city:'',
                    address:'',
                    state_id:'',
                    photo:'',
                    country:'',
                    is_verify:'',
                    job:'',
                    profile_content:'',
                    slug:'',


                },
                rules: {
                    required: value => !!value || "Required.",
                    counter: value => value.length <= 20 || "Max 20 characters",
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || "Invalid e-mail address.";
                    }
                }
            }
        },
        computed: {
            profile() {
              let  p =  this.$store.state.user_module.user;
            },
            states(){
                return this.$store.state.general_module.all_states;
            }
        },
        watch:{
            avatar: {
                handler: function() {
                    this.saved = false
                },
                deep: true
            },

        },
        methods: {
            launchFilePicker(){
                this.$refs.file.click();
            },
            onFileChange(fieldName, file) {
                const { maxSize } = this
                let imageFile = file[0]
                if (file.length>0) {
                    let size = imageFile.size / maxSize / maxSize
                    if (!imageFile.type.match('image.*')) {
                        // check whether the upload is an image
                        this.errorDialog = true
                        this.errorText = 'Please choose an image file'
                    } else if (size>1) {
                        // check whether the size is greater than the size limit
                        this.errorDialog = true
                        this.errorText = 'Your file is too big! Please select an image under 1MB'
                    } else {
                        // Append file into FormData and turn file into image URL
                        let formData = new FormData()
                        var reader = new FileReader()
                        reader.readAsDataURL(imageFile)
                        reader.onload = ()=> {
                            this.avatar.formData = reader.result;
                            // console.log(reader.result);
                        };

                        let imageURL = URL.createObjectURL(imageFile)
                        this.avatar.imageURL = imageURL
                        // Emit the FormData and image URL to the parent component
                        // this.$emit('input', { formData, imageURL })
                    }
                }
            },
            updateProfile() {
                if(!this.$refs.profile.validate()){
                    this.step = 1;
                    toast.error("Required field are empty")
                }

                this.user.photo = this.avatar.formData;
                this.$store.dispatch("updateProfileAction",this.user).then((res)=>{
                    console.log('profile updated')
                    this.$router.push({name:'profile'});
                })
            },
            uploadImage() {
                this.saving = true
                setTimeout(() => this.savedAvatar(), 1000)
            },
            savedAvatar() {
                this.saving = false
                this.saved = true
            }
        },
        created() {
            this.user = this.$store.state.user_module.user;
            this.avatar.imageURL = this.user.photo
        }
    }
</script>

<style scoped>
    .v-stepper{
        border-radius: 0 !important;
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .v-stepper__header {
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .step_button{
        display: flex;
        justify-content: space-between;
    }
</style>